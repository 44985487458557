<footer id="footer" class="footer">
  <div class="footer_ether">

    <div class="footer_ether_block clearfix">

      <div class="ether_logo_white">
        <img src="assets/logo-white.png" alt="icon" class="logo_white">
        <p class="footer_logo_text">
          A New Reality
        </p>
      </div>

      <div class="footer_ether_download">
        <p class="footer_ether_download_text">
          Download CEEK VR Now!
        </p>

        <div class="footer_s_net clearfix">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ceek.virtualrealityconcerts&hl=ru&gl=US" class="footer_get_googleplay"><img src="assets/google-button.png"></a>
          <a target="_blank" href="https://apps.apple.com/us/app/ceek-virtual-reality/id1169054349" class="footer_download_appstore"><img src="assets/app-store-button.png"></a>
        </div>


        <div class="footer_ether_context">
          <a class="footer_text_policy" href="https://www.ceekvr.com/privacy" target="_blank">
            Privacy Policy
          </a>
          <a class="footer_text_community" href="https://www.ceek.io/community/" target="_blank">
            Community
          </a>
          <a class="footer_text_announcements" href="https://www.ceekvr.com/news" target="_blank">
            Announcements
          </a>
        </div>

        <div class="footer_ether_link clearfix">
          <div class="ether_link_icon"><a href="https://t.me/ceekvrtokensale" target="_blank"><i class="fab fa-telegram-plane"></i></a></div>
          <div class="ether_link_icon padded"><a href="https://www.facebook.com/ceekapp/" target="_blank"><i class="fab fa-facebook-f"></i></a></div>
          <div class="ether_link_icon padded"><a href="https://twitter.com/ceek"c><i class="fab fa-twitter"></i></a></div>
          <div class="ether_link_icon padded"><a href="https://www.instagram.com/ceekvr/" target="_blank"><i class="fab fa-instagram"></i></a></div>
          <div class="ether_link_icon padded"><a href="https://www.youtube.com/channel/UCkt5aX3nCQhdREFCeVYD92g" target="_blank"><i class="fab fa-youtube"></i></a></div>
        </div>
      </div>
    </div>

    <hr class="footer_ether_line">

    <div class="footer_ether_text">
      <p> {{ year }} CEEK GLOBAL INNOVATIONS ALL Rights Reserved</p>
    </div>
  </div>
</footer>
