import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, retryWhen, take, delay as _delay, map } from 'rxjs/operators';

const delayedRetry = (delay, retries = 1) => retryWhen(result => {
    let _retries = 0;
    return result.pipe(
      _delay(delay),
      map(error => {
        if (_retries++ === retries) {
          throw error;
        }
        return error;
      }),
    );
  },
)

@Injectable({
  providedIn: 'root'
})
export class SignService
{

  private urls = {
    'bep20': 'https://bridgeapi.ceek.io/api/signbsc?code=oA2jffalvp1Cn/9EcIwoU8bKNt16rf7HlNLoVEXJlqSvV6z74dViBQ==',
    'erc20': 'https://bridgeapi.ceek.io/api/signeth?code=5w7tH8Wpz3XuXY8y3JgZz/xoMnbSM4yehtkKwON4tJ08BwFjpRGYsQ=='
  };

  constructor(public http: HttpClient) { }

  sign(ethTxId: string, tokenReceive: string) {
      return this.http.post(this.urls[tokenReceive], {txid: ethTxId}).pipe(
        delayedRetry(1000, 20),
        catchError((err) => {
          console.log('error while fetching signature')
          console.error(err);
          //Handle the error here
          return throwError(err);    //Rethrow it back to component
        })
      )
  }
}

