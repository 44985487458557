import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-wrongaccountdiag',
  templateUrl: './wrongaccountdiag.component.html',
  styleUrls: ['./wrongaccountdiag.component.scss']
})
export class WrongaccountdiagComponent {

  constructor(
    public dialogRef: MatDialogRef<WrongaccountdiagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WrongAccountDiagData
  ) {

  }
}
export interface WrongAccountDiagData {
  expectedAccount: string;
}
