import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-txdiag',
  templateUrl: './txdiag.component.html',
  styleUrls: ['./txdiag.component.scss']
})
export class TxdiagComponent {
  constructor(
    public dialogRef: MatDialogRef<TxdiagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TxdiagData
  ) {

  }
}
export interface TxdiagData {
  hash: string;
  receipt: boolean;
  confirmations: number;
  confsRequired: number;
}
