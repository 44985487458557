import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveComponentModule } from '@ngrx/component';
import { NgxMaskModule, IConfig } from 'ngx-mask'

// Directives
// import { NumericDirective } from "./numeric.directive";

// Components
import { AccountComponent } from './account/account.component';
import { BalancesComponent } from './balances/balances.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { SendComponent } from './send/send.component';
import { ErrorComponent } from './error/error.component';
import { TxdiagComponent } from "./txdiag/txdiag.component";
import { WrongaccountdiagComponent } from "./wrongaccountdiag/wrongaccountdiag.component";

import { AppMaterialModule } from "../app-material.module";

// Routing
import { UiRoute } from "./ui.routes";
import { RouterModule } from "@angular/router";

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AccountComponent,
    HomeComponent,
    TopNavComponent,
    SendComponent,
    ErrorComponent,
    BalancesComponent,
    FooterComponent,
    TxdiagComponent,
    WrongaccountdiagComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(UiRoute),
    AppMaterialModule,
    ReactiveFormsModule,
    ReactiveComponentModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  exports: [
    TopNavComponent,
    HomeComponent,
    FooterComponent,
    TxdiagComponent,
    WrongaccountdiagComponent,
    SendComponent
  ],
  providers: [],
})
export class UiModule { }
