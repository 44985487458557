<div>
  <a [routerLink]="" (click)="connectAccount()" [hidden]="(isConnected$ | ngrxPush)" class="account_wallet floating clearfix">
    <div class="header_address_wallet"></div>
    <div class="account_wallet__address">Connect Wallet</div>
  </a>

  <div [hidden]="!(isConnected$ | ngrxPush)" class="account_wallet connected clearfix">
    <div class="header_address_wallet"></div>
    <div class="account_wallet__address">{{account$ | ngrxPush}} </div>
    <a class="account_wallet__disconnect" title="Disconnect wallet" [routerLink]="" (click)="disconnectAccount()"><i class="fas fa-sign-out-alt"></i></a>
  </div>
</div>
