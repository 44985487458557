import { BlockchainService } from '../../services/contract/blockchain.service';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {Observable} from "rxjs";
import {formatNumber} from "@angular/common";

@Component({
  selector: 'app-balances',
  templateUrl: './balances.component.html',
  styleUrls: ['./balances.component.scss']
})
export class BalancesComponent {
  public isConnected$: Observable<boolean>;
  public network$: Observable<number>;
  public ceekBalance$: Observable<string>;
  public chainBalance$: Observable<string>;

  constructor(private bc: BlockchainService, private sanitizer: DomSanitizer) {
    this.isConnected$ = this.bc.isConnected()
    this.network$ = this.bc.getNetwork()
    this.ceekBalance$ = this.bc.getCeekBalance()
    this.chainBalance$ = this.bc.getChainBalance()
  }

  getFontSize(value) {
    let textLength = formatNumber(value, 'en-US', "1.0-2").length;
    const baseLength = 7
    const baseSize = 16;
    let fontSize = baseSize;
    let diff = textLength - baseLength
    if (diff > 0) {
      let reduce = Number(Math.pow(diff, 1.11).toFixed(2));
      fontSize = baseSize - reduce
      if (fontSize < 5) {
        fontSize = 5
      }
    }

    return `${fontSize}px`
  }
}
