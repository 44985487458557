import { Routes } from '@angular/router';

// Components
import {ErrorComponent} from "./error/error.component";
import {HomeComponent} from "./home/home.component";
import {SendComponent} from "./send/send.component";

export const UiRoute: Routes = [
  { path: '', component: SendComponent },
  { path: 'claim/:token/:txid', component: SendComponent },
  { path: '404', component: ErrorComponent },
  { path: '**', redirectTo: '/404' },
];
