<section id="content" class="content">
  <div class="overlay" [hidden]="isConnected"></div>
  <div class="overlay" [hidden]="isConnected">
    <div>
      <p class="mobile"><br><br></p>
      <p class="pls_cnnct">Please Connect Wallet</p>
      <br>
      <a [routerLink]="" (click)="connectAccount()" class="account_wallet clearfix">
        <div class="header_address_wallet"></div>
        <div class="account_wallet__address">Connect Wallet</div>
      </a>
    </div>
  </div>


  <div>
    <h1 class="content_welcome">
      Welcome to the CEEK 2 Way bridge for <span class="blue">ERC20</span>&nbsp;<>&nbsp;<span class="blue">BEP20</span>
    </h1>
    <div class="direction_desc">
    <span *ngIf="tokenGive==='bep20' && isConnected">
      <div class="network_logo_bsc"><img src="assets/sidebar_bnc.png" alt="icon" class="binance_vector_img"></div>
      <p class="binance_text_smart">Binance Smart Chain</p>&nbsp;
      <div class="direction_desc__arrow"><i class="fas fa-arrow-right"></i></div>&nbsp;
      <span><div class="network_logo_eth"><img src="assets/sidebar_eth.svg" alt="icon" class="erc_img"></div>
      <p class="binance_text_eth">Ethereum</p></span>
    </span>

    <span *ngIf="tokenGive==='erc20' && isConnected">
      <span><div class="network_logo_eth"><img src="assets/sidebar_eth.svg" alt="icon" class="erc_img"></div>
      <p class="binance_text_eth">Ethereum</p></span>&nbsp;
      <div class="direction_desc__arrow"><i class="fas fa-arrow-right"></i></div>&nbsp;
      <div class="network_logo_bsc"><img src="assets/sidebar_bnc.png" alt="icon" class="binance_vector_img"></div>
      <p class="binance_text_smart">Binance Smart Chain</p>
    </span>

      <!--    <a href="/send/{{tokenGive === 'erc20' ? 'bep20' : 'erc20'}}" class="direction_switch"><i class="fas fa-exchange-alt"></i></a>-->
    </div>

    <div class="content_bridge_impression">
      <div class="content_card clearfix">
        <div class="side_col" [ngStyle]="{'visibility': !isConnected ? 'hidden' : 'visible'}" >
          <img src="assets/sidebar_{{tokenGive==='erc20' ? 'eth' : 'bsc'}}.svg"
               class="sidebar_logo_{{tokenGive==='erc20' ? 'eth' : 'bsc'}}">
          <p class="content_card_text">
            {{ tokenGive | uppercase }}
          </p>
        </div>

        <div class="content_card_unlocking" [ngStyle]="{'background-color': (!isConnected ? '#fff' : 'inherit'), 'border-radius': (!isConnected ? '30px' : 'inherit')}">
          <div class="ethbscbg" [hidden]="isConnected"></div>
          <div [ngStyle]="{opacity: isConnected ? '1' : '0'}">
            <div class="unlocking_box">
              <p class="step_guide" [class.grey]="canClaimTokens">1. Input <span class="bold"> CEEK Tokens </span> amount to transfer and approve <span class="bold">CEEK Bridge</span> to move your tokens.</p>

              <div class="unlocking_box_block clearfix" [ngClass]="tokenGive" [hidden]="showApproveMenu === true">

                <div class="unlocking_net">
                  <p class="unlocking_net_text">
                    {{network === 1 ? 'ETH' : 'BSC'}} Network
                  </p>
                  <div class="unlocking_net_ceek">
                    <img src="assets/ceek-balance-logo.png" alt="icon" class="logo_blum_small">
                    <p class="unlocking_net_script bold">
                      CEEK
                    </p>
                  </div>
                </div>

                <div class="unlocking_lock_score clearfix" [ngClass]="tokenGive">
                  <button (click)="showApproveMenu = true" [disabled]="!approveEnabled" class="unlocking_lock_clickable">
                    <i class="fas fa-unlock"></i>
                  </button>
                  <div class="unlocking_amount_wrapper">
                    <p class="unlocking_amount_usd_val"><span>{{((ceekBalance / 1e18) | number: '1.0-5':'en-US') || 'no'}} <span class="desktop">CEEK available</span></span></p>
                    <input decimals="18" type="text"
                           [formControl]="amount" class="unlocking_amount bold" autocomplete="off"
                           mask="separator.18"
                           thousandSeparator=","
                    >
                  </div>
                </div>

              </div>

              <div class="content_link" [ngClass]="tokenGive" [hidden]="showApproveMenu === false">

                <button class="content_unlock_amount gradi-button unlock-button" (click)="approve(false)">
                  Unlock <br> <span class="bold" [style.font-size]="getUnlockAmountFontSize()">{{amount.value | number:'1.0-2'}}</span>
                </button>
                <button
                  class="content_unlock_infinite bold green-button unlock-button" (click)="approve(true)"
                >Infinite Unlock</button>
                <button class="content_unlock_cancel gradi-button unlock-button" (click)="showApproveMenu = false">
                  <i class="fas fa-times"></i>
                  <p class="content_cancel">Cancel</p>
                </button>

              </div>

              <hr class="unlocking_partition">
              <p class="step_guide" [class.grey]="canClaimTokens">2. Make the transfer</p>
              <p class="step_guide" [class.grey]="!canClaimTokens">3. Switch your wallet to <span class="bold">{{tokenReceive==='erc20' ? 'Ethereum network' : 'Binance Smart Chain'}}</span> and <br> claim your <span class="bold"> CEEK Tokens </span></p>
            </div>
            <button class="gradi-button unlocking_claim_clickable" (click)="takeMyTokens()" [disabled]="!canDeposit" [hidden]="canClaimTokens || !isConnected">
              <p class="claim_click_text">
                Transfer <span class="desktop"><span class="bold">{{amount.value| currency:'USD':'':'1.0-5'}}</span> {{tokenGive==='erc20' ? 'ERC20' : 'BEP20'}}</span> Token{{amount.value > 1 ? 's' : ''}}
              </p>
            </button>

            <button class="gradi-button unlocking_claim_clickable" (click)="claim()" [disabled]="canDeposit" [hidden]="!canClaimTokens || !isConnected">
              <p class="claim_click_text">
                Claim <span class="desktop"><span class="bold">{{amount.value| currency:'USD':'':'1.0-5'}}</span> {{tokenReceive==='erc20' ? 'ERC20' : 'BEP20'}}</span> Token{{amount.value > 1 ? 's' : ''}}
              </p>
            </button>
          </div>
        </div>

        <div class="side_col" [ngStyle]="{'visibility': !isConnected ? 'hidden' : 'visible'}" >
          <img src="assets/sidebar_{{tokenGive==='erc20' ? 'bsc' : 'eth'}}.svg"
               class="sidebar_logo_{{tokenGive==='erc20' ? 'bsc' : 'eth'}}">
          <p class="content_card_text">
            {{tokenGive==='erc20' ? 'BEP20' : 'ERC20'}}
          </p>
        </div>
      </div>
    </div>
  </div>

</section>
