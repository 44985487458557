import { Component, OnInit } from '@angular/core';
import { formatNumber } from '@angular/common';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, first, take } from 'rxjs/operators';
import { BN } from "web3-utils";
import { NgxMaskModule } from 'ngx-mask'

import { BlockchainService } from '../../services/contract/blockchain.service';
import { SignService } from '../../services/sign.service';

import { TxdiagComponent } from "../txdiag/txdiag.component";
import { NetworkchangediagComponent } from "../networkchangediag/networkchangediag.component";
import { WrongaccountdiagComponent } from "../wrongaccountdiag/wrongaccountdiag.component";

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.scss']
})

export class SendComponent implements OnInit {

  public amount = new FormControl('');
  public approveEnabled: boolean = false;
  public showApproveMenu: boolean = false;
  public tokenGive: string;
  public tokenReceive: string;

  public isConnected: boolean;
  public network: number;
  public expectedNetId: number;
  public account;
  public expectedAccount;
  public ceekBalance: any;
  public chainBalance: string;
  public allowance: string;

  public canDeposit: boolean = false;
  public canChangeAmount: boolean = true;
  public canClaimTokens: boolean = false;
  public transferId;
  public tmtTxData;
  public signature;

  public changeNetworkDiag;
  public wrongAccountDiag;

  public tokenBEP20 = 'bep20';
  public tokenERC20 = 'erc20';

  // @todo make private what suppose to be private
  constructor(
    public bc: BlockchainService,
    private route: ActivatedRoute,
    public router:Router,
    public dialog: MatDialog,
    private snack:  MatSnackBar,
    public sign: SignService
  ) {
    const that = this;

    this.bc.isConnected().subscribe(value => {
      this.isConnected = value
      this.setApproveEnabled()
      setCanDeposit()
    })

    this.bc.getNetwork().subscribe(value => {
      if (this.network !== value) {

        if(!this.bc.isSupportedNet()) {
          this.openChangeNetworkDiag(value)
        // } else if (this.expectedNetId && this.network !== this.expectedNetId) {
        //   this.openChangeNetworkDiag(this.network)
        } else {
          this.network = value;
          if (!this.tmtTxData) {
            if (value == 1) {
              this.tokenGive = this.tokenERC20
              this.tokenReceive = this.tokenBEP20
            } else if (value == 56) {
              this.tokenGive = this.tokenBEP20
              this.tokenReceive = this.tokenERC20
            }
          }

          this.closeChangeNetworkDiag()
          this.setApproveEnabled()
          setCanDeposit()
          document.getElementsByTagName('body')[0].click()
        }
      }
    })

    this.bc.getCeekBalance().subscribe(value => {
      if (value === null) {
        return
      }
      this.ceekBalance = value

      if (!this.amount.dirty){
        let ceekBalanceStr = this.ceekBalance.toString()
        let tokensCeil = ((new BN(ceekBalanceStr)).div(new BN('1'.padEnd(19, '0')))).toString();
        let tokensDecimal = ceekBalanceStr.substring(tokensCeil.length).replace(/0+$/, "");

        this.amount.setValue(
          (tokensCeil + (tokensDecimal  ? '.' + tokensDecimal : ''))
        );
      }
      this.setApproveEnabled()
      setCanDeposit()
    })

    this.bc.getChainBalance().subscribe(value => {
      this.chainBalance = value;
    })

    this.bc.getAllowance().subscribe(value => {
      this.allowance = value;
      this.setApproveEnabled();
      setCanDeposit();
    })

    this.bc.getAccount().subscribe(value=> {
      if (value) {
        this.account = value;
      }

      if (value && this.expectedAccount) {
        if (value.toLowerCase() !== this.expectedAccount.toLowerCase()) {
          this.openWrongAccountDiag()
        } else if (this.wrongAccountDiag) {
          this.wrongAccountDiag.close()
          this.wrongAccountDiag = null
        }
      }
      document.getElementsByTagName('body')[0].click()
    })

    this.amount.valueChanges.subscribe(value => {
      this.setApproveEnabled();
      setCanDeposit();
    });

    function setCanDeposit() {
      if (that.isConnected) {
        let allowance = new BN(that.allowance)
        let ceekBalance = new BN(that.ceekBalance);
        let giveAmount = that.getAmountBN();
        if (
          giveAmount.toString() !== '0'
          && giveAmount.gte(new BN(0))
          && allowance.gte(giveAmount)
          && ceekBalance.gte(giveAmount)
          && !(that.tmtTxData)
        ) {
          that.canDeposit = true;
          return;
        }
      }
      that.canDeposit = false;
    }

  }

  setApproveEnabled() {
    if (this.isConnected) {
      let allowance = new BN(this.allowance)
      let ceekBalance = new BN(this.ceekBalance);
      let giveAmount = this.getAmountBN();
      if (allowance.lt(giveAmount) && ceekBalance.gte(giveAmount) && !this.canClaimTokens) {
        this.approveEnabled = true;
        return;
      }
    }

    this.approveEnabled = false;
  }

  closeChangeNetworkDiag() {
    if (this.changeNetworkDiag) {
      this.changeNetworkDiag.close()
      document.getElementsByTagName('body')[0].click()
      delete this.changeNetworkDiag
    }
  }

  openChangeNetworkDiag(netId: number) {
    if (this.changeNetworkDiag) {
      document.getElementsByTagName('body')[0].click()
      return
    }
    let data = {currentNetId: netId, expectedNetId: this.expectedNetId}
    this.changeNetworkDiag = this.dialog.open(
      NetworkchangediagComponent,
      {width: '450px', data: data, disableClose: true}
    )
    document.getElementsByTagName('body')[0].click()
  }

  getAmountBN() {
    let [ceilAmount, decimalAmount] = this.amount.value.split('.');
    decimalAmount = (decimalAmount === undefined) ? ''.padEnd(18, '0') : decimalAmount.slice(0, 18).padEnd(18, '0');
    return new BN(ceilAmount + decimalAmount);
  }

  getUnlockAmountFontSize() {
    let textLength = formatNumber(this.amount.value, 'en-US', "1.0-2").length;
    const baseLength = 8
    const baseSize = 16;
    let fontSize = baseSize;
    let diff = textLength - baseLength
    if (diff > 0) {
      let reduce = Number(Math.pow(diff, 1.11).toFixed(2));
      fontSize = baseSize - reduce
      if (fontSize < 5) {
        fontSize = 5
      }
    }
    return `${fontSize}px`
  }

  async approve(unlim: boolean) {
    let amount: string;
    if (unlim) {
      amount = new BN(''.padEnd(64, 'f')).toString();
    } else {
      amount = this.getAmountBN().toString()
    }

    let txData = {hash:null, receipt: false, confirmations: 0, confsRequired: 1}
    let txPromise = this.bc.approve(amount);

    let diag = this.dialog.open(TxdiagComponent, {width: '450px', data: txData, disableClose: true})

    txPromise.on('confirmation', (confirmations) => {
      diag.componentInstance.data.confirmations = confirmations
      document.getElementsByTagName('body')[0].click()
    })
    txPromise.on('transactionHash', (hash) => {
      diag.componentInstance.data.hash = hash
      document.getElementsByTagName('body')[0].click()
    } )
    txPromise.on('receipt', (receipt) => { diag.componentInstance.data.receipt = true })

    txPromise.on('finalized', () => {
      let result = 'Transaction confirmed. You can transfer tokens now.'
      diag.close(result)
      document.getElementsByTagName('body')[0].click()
      this.snack.open(result, null, {duration: 3000})
      this.showApproveMenu = false;
    });

    txPromise.on('error', (receipt) => {
      let result = 'Transaction failed.';
      diag.close(result)
      // for some reason material won't close the dialog untill click outside of it. God Almighty knows why...
      document.getElementsByTagName('body')[0].click()
      this.snack.open(result, null, {duration: 3000})
    })
  }

  takeMyTokens() {
    this.tmtTxData = {hash:null, receipt: false, confirmations: 0, confsRequired: this.bc.confsRequired}
    let txPromise = this.bc.takeMyTokens(this.getAmountBN().toString());
    let diag = this.dialog.open(TxdiagComponent, {width: '450px', data: this.tmtTxData, disableClose: true})
    this.amount.disable()
    this.approveEnabled = false
    txPromise.on('confirmation', (confirmations) => {
      this.tmtTxData.confirmations = diag.componentInstance.data.confirmations = confirmations
      document.getElementsByTagName('body')[0].click()
    })
    txPromise.on('transactionHash', (hash) => {
      this.tmtTxData.hash = diag.componentInstance.data.hash = hash
      this.setClaimUrl()
      document.getElementsByTagName('body')[0].click()
    })
    txPromise.on('receipt', (receipt) => {
      this.tmtTxData.receipt = diag.componentInstance.data.receipt = true
    })

    txPromise.on('finalized', () => {
      let result = 'Transaction confirmed.';
      diag.close(result)
      document.getElementsByTagName('body')[0].click()
      this.snack.open(result, null, {duration: 3000})
      this.fetchSign()
      this.expectedNetId = this.network === 1 ? 56 : 1;
      this.openChangeNetworkDiag(this.network)
    });

    txPromise.on('error', (receipt) => {
      let result = 'Transaction failed.';
      diag.close(result)
      this.amount.enable()
      this.setApproveEnabled()
      this.canDeposit = true
      this.tmtTxData = null
      // for some reason material won't close the dialog untill click outside of it. God Almighty knows why...
      document.getElementsByTagName('body')[0].click()
      this.snack.open(result, null, {duration: 3000})
    })
  }

  setClaimUrl() {
    let url = this.router.createUrlTree(['claim', this.tokenReceive, this.tmtTxData.hash]).toString();
    (window as any).history.pushState("", "", url);
  }

  async fetchSign()
  {
    this.snack.open('Signing your transfer...', null)
    this.amount.markAsDirty()
    this.sign.sign(this.tmtTxData.hash, this.tokenReceive).subscribe(
        data => {
          this.signature = (data as any).signature
          this.canClaimTokens = true
          this.approveEnabled = false

          this.snack.dismiss()
          this.snack.open('Signed. Claim your tokens now.', null, {duration: 3000})
          let tokensCeil = ((new BN((data as any).amount)).div(new BN('1'.padEnd(19, '0')))).toString()
          let tokensDecimal = (data as any).amount.substring(tokensCeil.length).replace(/0+$/, "")
          this.amount.setValue(
            (tokensCeil + (tokensDecimal  ? '.' + tokensDecimal : ''))
          )

          this.expectedAccount = (data as any).sender
          if (this.account && this.account.toLowerCase() != this.expectedAccount.toLowerCase()) {
            // we have to show change wallet dialog. But maybe change network dialog  is on
            if (this.changeNetworkDiag) {
              this.changeNetworkDiag.afterClosed().subscribe(() => {
                this.openWrongAccountDiag()
              })
            } else {
              this.openWrongAccountDiag()
            }
          }
          document.getElementsByTagName('body')[0].click()
        },
        error => {
          console.log(error)
          this.snack.open('Error code' + error.message + '. Try to refresh this page later.', null)
          document.getElementsByTagName('body')[0].click()
        }
      )
  }


  openWrongAccountDiag() {
    if (this.account && this.account.toLowerCase() !== this.expectedAccount.toLowerCase()) {
      console.log('Opening wrong account diag', this.account, this.expectedAccount)
      if (!this.wrongAccountDiag) {
        this.wrongAccountDiag = this.dialog.open(
          WrongaccountdiagComponent,
          {width: '450px', data: {expectedAccount: this.expectedAccount}, disableClose: true}
        )
      }
    }
  }

  async claim() {
    let txData = {hash:null, receipt: false, confirmations: 0, confsRequired: 1}
    let txPromise = this.bc.claimMyTokens(this.tmtTxData.hash, this.signature, this.getAmountBN().toString())
    txPromise.on('transactionHash', (hash) => {
      diag.componentInstance.data.hash = hash
      document.getElementsByTagName('body')[0].click()
    })
    txPromise.on('receipt', (receipt) => { diag.componentInstance.data.receipt = true })

    const diag =  this.dialog.open(TxdiagComponent, {width: '350px', data: txData, disableClose: true})
    txPromise.on('finalized', () => {
        diag.close()
        document.getElementsByTagName('body')[0].click()
        this.snack.open('Success! Thank you.', null, {duration: 10000})
        this.canClaimTokens = false
        setTimeout(() => {
          this.router.navigateByUrl('/', { replaceUrl: true}); // remove "claim" url from browser history
          // (window as any).history.replaceState("", "", '/');
          // (window as any).location = '/'
          document.getElementsByTagName('body')[0].click()
        }, 10000)
    })
    txPromise.on('error', (receipt) => {
      let result = 'Transaction failed.';
      diag.close(result)
      // for some reason material won't close the dialog untill click outside of it. God Almighty knows why...
      document.getElementsByTagName('body')[0].click()
      this.snack.open(result, null, {duration: 3000})
    })
  }

  connectAccount() {
    this.bc.connectAccount().then(() => {
    }).catch((error: any) => {
      this.snack.open('Could\'t get the account data, please check if metamask is running correctly and refresh the page', null, {duration: 3000})
    })
  }

  ngOnInit(): void {
    (window as any).scope = this;
    let params = this.route.snapshot.params;

    if(
      [this.tokenBEP20, this.tokenERC20].includes(params.token)
      && params.txid.match(/^0x([A-Fa-f0-9]{64})$/)
    ) {
      this.tmtTxData = {hash:params.txid, receipt: true, confirmations: 12, confsRequired: this.bc.confsRequired}
      this.tokenReceive = params.token
      this.tokenGive = (this.tokenReceive === this.tokenBEP20) ? this.tokenERC20 : this.tokenBEP20

      this.amount.disable()
      this.canDeposit = false

      this.expectedNetId = (this.tokenReceive === this.tokenBEP20) ? 56 : 1;
      this.bc.isConnected().subscribe(value => {
        if (value) {
          if (this.network != this.expectedNetId) {
            this.openChangeNetworkDiag(this.network)
          }
          this.fetchSign()
        }
      })
    }

  }

}
