import { Injectable } from '@angular/core';
import Contract from "web3-eth-contract";
import Web3PromiEvent from "web3-core-promievent";
import { Subject, BehaviorSubject } from 'rxjs';
import Web3Modal from "web3modal";

declare let require: any;
const Web3 = require('web3');
const tokenAbi = require('../../../../../Blockchain/build/contracts/CEEK.json');
const BSCGateAbi = require('../../../../../Blockchain/build/contracts/BSCGate.json');
const ETHGateAbi = require('../../../../../Blockchain/build/contracts/ETHGate.json');

declare let window: any;

@Injectable({
  providedIn: 'root'
})

export class BlockchainService {

  private isConnected$ = new BehaviorSubject<boolean>(false);
  private accountsObservable$ = new Subject<string>();
  private ceekBalance$ = new Subject<string>();
  private chainBalance$ = new Subject<string>();
  private allowance$ = new Subject<string>();
  private network$ = new Subject<number>();

  public confsRequired = 12;

  public compatible: boolean;
  web3Modal;
  web3js;
  provider;
  netId;
  accounts;
  balance;
  ceekBalance;
  allowance;

  contractCEEK;
  contractBSCGate;
  contractETHGate;


  constructor() {
    this.initWeb3Modal()
    this.initContracts()
  }

  initWeb3Modal() {
    let that = this
    const providerOptions = {}

    this.web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: false, // optional
      providerOptions, // required
      theme: {
        background: "rgb(39, 49, 56)",
        main: "rgb(199, 199, 199)",
        secondary: "rgb(136, 136, 136)",
        border: "rgba(195, 195, 195, 0.14)",
        hover: "rgb(16, 26, 32)"
      }
    });

    this.web3Modal.on('connect', async (info: { chainId: string }) => {
      console.log('web3Modal connect', info)
      that.setNetwork(parseInt(info.chainId))
    });
  }

  initContracts() {
    this.contractCEEK = new Contract(tokenAbi.abi);
    this.contractBSCGate = new Contract(BSCGateAbi.abi);
    this.contractBSCGate.tMethod = 'transitForETH';
    this.contractBSCGate.wMethod = 'withdrawFromETH';
    this.contractETHGate = new Contract(ETHGateAbi.abi);
    this.contractETHGate.tMethod = 'transitForBSC';
    this.contractETHGate.wMethod = 'transitForBSC';
  }

  setAccounts(accounts: string[]) {
    this.accounts = accounts;
    this.accountsObservable$.next(this.accounts[0]);
    this.contractCEEK.defaultAccount = this.accounts[0];
    this.contractBSCGate.defaultAccount = this.accounts[0];
    this.contractBSCGate.contractETHGate = this.accounts[0];
    this.web3js.eth.defaultAccount = this.accounts[0];
    this.isConnected$.next(true);
    this.getDetails();
  }

  setContractsProvider() {
    this.contractCEEK.setProvider(this.provider);
    this.contractBSCGate.setProvider(this.provider);
    this.contractETHGate.setProvider(this.provider);
  }

  setNetwork(netId) {
    this.netId = netId;
    if (this.isSupportedNet()) {
      if (tokenAbi.networks[netId.toString()])
        this.contractCEEK.options.address = tokenAbi.networks[netId.toString()].address
      if (BSCGateAbi.networks[netId.toString()])
        this.contractBSCGate.options.address = BSCGateAbi.networks[netId.toString()].address
      if (ETHGateAbi.networks[netId.toString()])
        this.contractETHGate.options.address = ETHGateAbi.networks[netId.toString()].address
    }
    this.network$.next(this.netId);
  }

  async connectAccount() {
    let that = this;
    this.provider = await this.web3Modal.connect(); // set provider
    this.provider.on('chainChanged', (chainId) => {
      console.log('chainChanged', chainId)
      that.setNetwork(parseInt(chainId))
      if (this.isSupportedNet()) {
        that.getDetails()
      }
    })
    this.provider.on('accountsChanged', (accounts: string[]) => {
      console.log('accountsChanged')
      this.setAccounts(accounts)
    })
    this.setContractsProvider()
    this.setNetwork(parseInt(this.provider.chainId))
    this.web3js = new Web3(this.provider) // create web3 instance
    this.web3js.eth.transactionPollingTimeout = 360000
    this.setAccounts(await this.web3js.eth.getAccounts())
    return true
  }

  async disconnectAccount() {
    if(this.provider.close) {
      await this.provider.close();
    }
    await this.web3Modal.clearCachedProvider();
    this.provider = null;

    this.isConnected$.next(false);
    this.accountsObservable$.next(null);
    this.chainBalance$.next(null);
    this.ceekBalance$.next(null);
  }

  getAccount() {
    return this.accountsObservable$.asObservable();
  }

  getCeekBalance() {
    return this.ceekBalance$.asObservable();
  }

  getChainBalance() {
    return this.chainBalance$.asObservable();
  }

  getAllowance() {
    return this.allowance$.asObservable();
  }

  getNetwork() {
    return this.network$.asObservable();
  }

  isConnected() {
    return this.isConnected$.asObservable();
  }

  async getDetails() {
    this.balance = await this.web3js.eth.getBalance(this.accounts[0]);
    this.chainBalance$.next(this.balance);
    this.ceekBalance = await this.contractCEEK.methods.balanceOf(this.accounts[0]).call();
    this.ceekBalance$.next(this.ceekBalance);
    let gate = this.getGate();
    this.allowance = await this.contractCEEK.methods.allowance(this.accounts[0], gate.options.address).call();
    this.allowance$.next(this.allowance);

    return [this.balance, this.ceekBalance, this.allowance, this.netId];
  }

  getGate() {
    if (this.netId===1) {
      return this.contractETHGate;
    }
    if (this.netId===56) {
      return this.contractBSCGate;
    }
  }

  trackTxPromi(promi: Web3PromiEvent, confsRequired?: number) {
    let that = this;
    if(!confsRequired) confsRequired = this.confsRequired

    function trackFinalization(confirmations) {
      console.log('confirmations', confirmations, confsRequired)
      if (confirmations == confsRequired) {
        console.log('Emitting finalized', promi.emit('finalized'))
      }
    }
    promi.on('confirmation', trackFinalization);
    promi.on('finalized', () => {
      that.getDetails()
      promi.removeListener('confirmation')
      promi.removeListener('error')
      console.log('finalized', 'trackFinalization removed')
    })
  }

  approve(amount: string): Web3PromiEvent {
    let promi = this.contractCEEK.methods.approve(this.getGate().options.address, amount).send({from: this.accounts[0]});
    this.trackTxPromi(promi, 1);
    return promi;
  }

  takeMyTokens(amount: string): Web3PromiEvent {
    const gate = this.getGate();
    const method = gate.methods[gate['tMethod']];
    let promi = method(this.contractCEEK.options.address, amount).send({from: this.accounts[0]});
    this.trackTxPromi(promi);
    return promi;
  }

  claimMyTokens(transferId: string, signature: string, amount: string): Web3PromiEvent {
    if (this.netId === 1) {
      let promi = this.contractETHGate.methods.withdrawFromBSC(
        signature, transferId, tokenAbi.networks['1'].address, amount
      ).send({from: this.accounts[0]});
      this.trackTxPromi(promi, 1);
      return promi;
    }

    if (this.netId === 56) {
      let promi = this.contractBSCGate.methods.withdrawFromETH(
        signature, transferId, amount, tokenAbi.networks['1'].address, 'CEEK', 'CEEK', 18
      ).send({from: this.accounts[0]});
      this.trackTxPromi(promi, 1);
      return promi;
    }
  }

  isSupportedNet() {
    return  [1, 56].includes(this.netId);
  }

}
