import { BlockchainService } from './../../services/contract/blockchain.service';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Identicon } from '../../services/identicon';
import { Md5 } from 'ts-md5/dist/md5';
import {Observable} from "rxjs";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  account$: Observable<string>;
  isConnected$: Observable<boolean>;
  balance: string;
  data;
  accountDetails;

  constructor(private bc: BlockchainService, private sanitizer: DomSanitizer) {
    this.account$ = this.bc.getAccount();
    this.isConnected$ = this.bc.isConnected();
  }

  getImage() {
    // this.data = this.sanitizer.bypassSecurityTrustResourceUrl((
    //   'data:image/svg+xml; utf8,'
    //   + encodeURI(new Identicon(Md5.hashStr(this.direction), {size: 32, format: 'svg'}).toString(true))
    // ));
  }

  connectAccount() {
    return this.bc.connectAccount();
  }

  disconnectAccount() {
    return this.bc.disconnectAccount();
  }

  isConnected() {
    return this.bc.isConnected();
  }


}
