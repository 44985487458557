<div>
  <div class="header_transactions" [hidden]="!(chainBalance$ | ngrxPush) || !(isConnected$ | ngrxPush)">
    <div class="transactions_left">
      <h6 class="transactions_eth_balance">
        {{ (network$ | ngrxPush) == 1 ? 'ETH' : 'BNB' }} Balance:
      </h6>
      <p class="eth_balance_sum" [style.font-size]="getFontSize(((chainBalance$ | ngrxPush) / 1e18))">
        {{ ((chainBalance$ | ngrxPush) / 1e18) | number: '1.0-5':'en-US'}}
      </p>
    </div>
  </div>

  <div class="header_ceek_balance clearfix" [hidden]="!(ceekBalance$ | ngrxPush) || !(isConnected$ | ngrxPush)">
    <div class="ceek_balance_logo"> </div>
    <h6 class="ceek_balance_caption">
      CEEK Balance
    </h6>

    <p class="ceek_balance_sum" [style.font-size]="getFontSize(((ceekBalance$ | ngrxPush) / 1e18))">
      {{ ((ceekBalance$ | ngrxPush) / 1e18) | number: '1.0-5':'en-US'}}
    </p>
  </div>
</div>
